<template>
  <div
    v-if="this.$route.matched.length > 2"
    class="animated fadeIn"
  >
    <router-view />
  </div>
  <div
    v-else
    class="animated fadeIn"
  >
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        visible
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Код рейса"
              >
                <el-select-clearable
                  v-model="filter.route_id"
                  class="form-control"
                  name="route-code"
                  placeholder="Начните вводить код рейса"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="contractorsAuctionsRoutesList"
                  :loading="loading"
                  @input="updateFilter('route_id')"
                  @clear="contractorsAuctionsRoutesList"
                >
                  <el-option
                    v-for="item in routeIds"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                class="form-group-el-select"
                label="Тип перевозки"
              >
                <el-select
                  v-model="filter.transportation_id"
                  class="form-control"
                  name="transportation_id"
                  placeholder=""
                  :loading="loading"
                  :disabled="filter.category === 'cancelled'"
                  @input="updateFilter('transportation_id')"
                >
                  <el-option
                    v-for="item in types"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                class="form-group-el-select"
                label="Статус"
              >
                <el-select-clearable
                  v-model="filter.status"
                  class="form-control"
                  name="status"
                  placeholder=""
                  :multiple="true"
                  clearable
                  :loading="loading"
                  :disabled="filter.category === 'graduated'"
                  @input="updateFilter('status')"
                >
                  <el-option
                    v-for="item in statuses"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Плановая дата прибытия от">
                <el-date-picker-input
                  v-model="filter.arrival_date_from"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  @change="updateDateFilter('arrival_date_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Плановая дата прибытия до">
                <el-date-picker-input
                  v-model="filter.arrival_date_to"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  @change="updateDateFilter('arrival_date_to')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата старта аукциона от">
                <el-date-picker-input
                  v-model="filter.start_date_from"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  :disabled="filter.category === 'drafts'"
                  @change="updateDateFilter('start_date_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата старта аукциона до">
                <el-date-picker-input
                  v-model="filter.start_date_to"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  :disabled="filter.category === 'drafts'"
                  @change="updateDateFilter('start_date_to')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата окончания аукциона от">
                <el-date-picker-input
                  v-model="filter.stop_date_from"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  :disabled="filter.category === 'drafts'"
                  @change="updateDateFilter('stop_date_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата окончания аукциона до">
                <el-date-picker-input
                  v-model="filter.stop_date_to"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  :disabled="filter.category === 'drafts'"
                  @change="updateDateFilter('stop_date_to')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Точка отправления"
              >
                <el-select
                  v-model="filter.first_point"
                  class="form-control"
                  name="first_point"
                  placeholder="Введите точку отправления"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :loading="loading"
                  :remote-method="firstDepartmentsList"
                  @input="updateFilter('first_point')"
                  @clear="firstDepartmentsList"
                >
                  <el-option
                    v-for="item in firstDepartments"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Конечная точка"
              >
                <el-select
                  v-model="filter.last_point"
                  class="form-control"
                  name="last_point"
                  placeholder="Введите конечную точку"
                  clearable
                  reserve-keyword
                  :filterable="true"
                  :loading="loading"
                  :remote="true"
                  :remote-method="lastDepartmentsList"
                  @input="updateFilter('last_point')"
                  @clear="lastDepartmentsList"
                >
                  <el-option
                    v-for="item in lastDepartments"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <ul class="nav nav-tabs auction-filter-tabs">
      <li
        v-for="(category, index) in categories"
        :key="'category-' + index"
        class="nav-item auction-filter-tabs__item"
      >
        <input
          :id="'category-' + index"
          v-model="filter.category"
          class="auction-filter-tabs__input"
          type="radio"
          name="category"
          :value="category.value"
          @change="updateFilter('category')"
        >
        <label
          :for="'category-' + index"
          class="nav-link auction-filter-tabs__display"
        >{{ category.text }}</label>
      </li>
    </ul>
    <b-card no-body>
      <b-card-header>
        Аукционы
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <flight-legend :transportation-types="types" />
        <v-server-table
          v-if="filter.ready"
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="routeCodes"
            slot-scope="props"
          >
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
              >
                {{ auctionRoute.route.routeCode }}
                <br>
              </span>
            </span>
              <span v-if="props.row.firstBidWin" class="text-blue">По первой ставке</span>
              <span v-else class="text-standart">Стандартный</span>
          </span>
          <span
            slot="routeWays"
            slot-scope="props"
          >
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
              >
                {{ auctionRoute.route.routeWay.name }}
                <br>
              </span>
            </span>
          </span>
          <span
            slot="transportationNames"
            slot-scope="props"
          >
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
              >
                <span
                  :style="`backgroundColor: ${auctionRoute.route.transportation.color};`"
                  class="flight-card"
                >
                  <span :style="auctionRoute.route.transportation.color == null ? 'color: black;' : 'color: white;'">{{ auctionRoute.route.transportation.name }}</span>
                </span>
                <br>
              </span>
            </span>
          </span>
          <span
            slot="transportationType"
            slot-scope="props"
          >
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
                class="nowrap"
              >
                {{ auctionRoute.route.transportationType.name }}
                <br>
              </span>
            </span>
          </span>
          <span
            slot="bodyTypes"
            slot-scope="props"
          >
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
                class="nowrap"
              >
                <span v-if="auctionRoute.bodyTypes && auctionRoute.bodyTypes.length > 0">
                  <span
                    v-for="(bodyType, index) in auctionRoute.bodyTypes"
                    :key="'bodyType-' + index"
                  >
                    <span
                      v-if="bodyType.name === 'Тент с усиленной обрешеткой'"
                      v-b-tooltip.hover.bottom="'Тент с усиленной обрешеткой'"
                      class="mr-1"
                    >
                      <img
                        src="/img/icons/body-types/wood.png"
                        alt="Тент с усиленной обрешеткой"
                      >
                    </span>
                    <span
                      v-else-if="bodyType.name === 'Изотермический'"
                      v-b-tooltip.hover.bottom="'Изотермический'"
                      class="mr-1"
                    >
                      <img
                        src="/img/icons/body-types/3d.png"
                        alt="Изотермический"
                      >
                    </span>
                    <span
                      v-else-if="bodyType.name === 'Контейнер'"
                      v-b-tooltip.hover.bottom="'Контейнер'"
                      class="mr-1"
                    >
                      <img
                        src="/img/icons/body-types/container.png"
                        alt="Контейнер"
                      >
                    </span>
                    <span
                      v-else-if="bodyType.name === 'Рефрижератор'"
                      v-b-tooltip.hover.bottom="'Рефрижератор'"
                      class="mr-1"
                    >
                      <img
                        src="/img/icons/body-types/temperature-control.png"
                        alt="Рефрижератор"
                      >
                    </span>
                    <span v-else>{{ bodyType.name }}</span>
                  </span>
                </span>
                <br>
              </span>
            </span>
          </span>
          <span
            slot="planDatesOfFirstPointArrive"
            slot-scope="props"
          >
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
              >
                {{ formatDatetimeWithOriginalTimezone(auctionRoute.route.planDateOfFirstPointArrive) }}<br>
              </span>
            </span>
          </span>
          <span
            slot="dimensions"
            slot-scope="props"
          >
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
                class="nowrap"
              >
                <span v-if="auctionRoute.dimension || auctionRoute.optionalDimensions.length > 0">
                  <span v-if="props.row.status === 4 || props.row.status === 6">
                    <span
                      v-if="props.row.smallestContractorBid && props.row.smallestContractorBid.winner"
                      class="text-success"
                    >
                      <dimensions-view
                        :value="dimensionsList(auctionRoute.id, auctionRoute.dimension, auctionRoute.optionalDimensions)"
                        :price="props.row.smallestContractorBid.bet"
                      />
                    </span>
                    <span
                      v-else-if="props.row.contractorOffer && props.row.contractorOffer.winner"
                      class="text-success"
                    >
                      <dimensions-view
                        :value="dimensionsList(auctionRoute.id, auctionRoute.dimension, auctionRoute.optionalDimensions)"
                        :price="props.row.contractorOffer.bet"
                      />
                    </span>
                    <span v-else>Н/Д</span>
                  </span>
                  <span v-else>
                    <dimensions-view
                      :value="dimensionsList(auctionRoute.id, auctionRoute.dimension, auctionRoute.optionalDimensions)"
                      :price="auctionRoute.startPrice"
                    />
                  </span>
                </span>
              </span>
            </span>
          </span>
          <span
            slot="startDate"
            slot-scope="props"
          >{{ formatDatetime(props.row.startDate) }}</span>
          <span
            slot="stopDate"
            slot-scope="props"
          >{{ props.row.factEndDate ? formatDatetime(props.row.factEndDate) : formatDatetime(props.row.stopDate) }}
          </span>
          <span
            slot="enableOffers"
            slot-scope="props"
          >
            <span v-if="props.row.enableOffers">
              <span v-if="props.row.offerDate">
                Принимаются с {{ formatDatetime(props.row.offerDate) }}
              </span>
              <span v-else>
                Да
              </span>
            </span>
            <span v-else>Нет</span>
          </span>
          <span
            slot="startPrice"
            slot-scope="props"
          >{{ numberRUFormat(props.row.startPrice) }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.startPrice) }}</span>
          <span
            slot="activeBid"
            slot-scope="props"
          >
            <span
              v-if="props.row.smallestBid"
              class="text-success"
            >{{ numberRUFormat(props.row.smallestBid.bet) }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.smallestBid.bet) }}</span>
            <span v-else>Н/Д</span>
          </span>
          <span
            slot="myBid"
            slot-scope="props"
          >
            <span v-if="props.row.status === 4 || props.row.status === 6">
              <span
                v-if="props.row.smallestContractorBid && props.row.smallestContractorBid.winner"
                class="text-success"
              >
                {{ numberRUFormat(props.row.smallestContractorBid.bet) }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.smallestContractorBid.bet) }}
              </span>
              <span
                v-else-if="props.row.contractorOffer && props.row.contractorOffer.winner"
                class="text-success"
              >
                {{ numberRUFormat(props.row.contractorOffer.bet) }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.contractorOffer.bet) }}
              </span>
              <span v-else>Н/Д</span>
            </span>
            <span v-else>
              <span v-if="props.row.smallestContractorBid">
                <span
                  v-if="props.row.smallestContractorBid.active"
                  class="text-success"
                >{{ numberRUFormat(props.row.smallestContractorBid.bet) }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.smallestContractorBid.bet) }}</span>
                <span
                  v-else
                  class="text-danger"
                >{{ numberRUFormat(props.row.smallestContractorBid.bet) }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.smallestContractorBid.bet) }} (проигрывает)</span><br>
              </span>
              <span v-if="props.row.contractorOffer">
                <span class="text-warning">{{ numberRUFormat(props.row.contractorOffer.bet) }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.contractorOffer.bet) }} (встречное)</span>
              </span>
              <span v-else>Н/Д</span>
            </span>
          </span>
          <span
            slot="status"
            slot-scope="props"
          >
            <span
              v-if="props.row.status === 1"
              class="text-primary"
            >Ожидает начала</span>
            <span
              v-else-if="props.row.status === 2"
              class="text-success"
            >Прием ставок</span>
            <span
              v-else-if="props.row.status === 3"
              class="text-primary"
            >Подведение итогов</span>
            <span v-else-if="props.row.status === 4">
              <span
                v-if="props.row.winner.id === $store.state.user.ownerId"
                class="text-success"
              >Победа</span>
              <span
                v-else
                class="text-primary"
              >Подведение итогов</span>
              <span />
            </span>
          </span>
          <span
            slot="actions"
            slot-scope="props"
            class="d-flex flex-wrap flex-column align-items-center justify-content-center"
          >
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              class="fa fa-eye fa-lg text-secondary table-action-button mb-2"
              :href="'/contractors/id' + $store.state.user.ownerId + '/auction/id' + props.row.id"
            />
            <a
            v-if="props.row.commentInfo"
            v-b-tooltip.hover.bottom="'Прочитать комментарий'"
            class="fa fa-exclamation fa-lg text-danger table-action-button mb-2"
            @click="showCommentModal(props.row)"
            />
            <a
              v-if="props.row.status === 4 && props.row.smallestContractorBid && props.row.smallestContractorBid.winner"
              v-b-tooltip.hover.bottom="'Отказаться от выигранного рейса'"
              class="fa fa-close fa-lg text-danger table-action-button mb-2"
              @click="confirmCancelAuction(props.row.id, 'bid')"
            />
            <a
              v-if="props.row.status === 4 && props.row.contractorOffer && props.row.contractorOffer.winner"
              v-b-tooltip.hover.bottom="'Отказаться от выигранного рейса'"
              class="fa fa-close fa-lg text-danger table-action-button mb-2"
              @click="confirmCancelAuction(props.row.id, 'offer')"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
    <b-modal
    v-model="showComment"
    :title="'Комментарии к аукциону ' + auctionName"
    class="modal-warning"
    cancel-title="Назад"
    ok-only
    >
    <div 
    v-for="(auctionRoute, index) in this.auctionRoutes"
    :key = "'index' + index"
    > 
    <span class="font-weight-bold">{{auctionRoute.routeWay.code}}:</span>
    {{auctionRoute.comment.comment}}
    </div>
    </b-modal>
  </div>
</template>

<script>
import DimensionsView from '../../components/DimensionsView.vue'
import ElDatePickerInput from '../../components/ElDatePickerInput.vue';
import ElSelectClearable from '../../components/ElSelectClearable.vue';
import Vue from 'vue';
import {ServerTable, Event} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import FlightLegend from '../../components/FlightLegend';
import {
  contractorsAuctionsList,
  contractorsAuctionAcceptWin,
  contractorsAuctionDeclineWin,
  contractorsAuctionsRoutesList,
  routesTransportationTypesList,
  routeOwnersList,
  departmentsList,
  enterToAuctionsPage,
} from '../../services/api';
import {valueToValueWithVAT, valueToValueWithoutVAT, numberToRUFormat} from '@/components/mixins/helpers';
import moment from 'moment';
import tz from 'moment-timezone';
import {arrayStringToNumber} from '@/components/mixins/helpers';
import {queryToUrl, URLToQuery} from '@/services/http';

Vue.use(ServerTable);

export default {
  name: 'ContractorAuctions',
  components: {
    ServerTable,
    Event,
    FlightLegend,
    ElSelectClearable,
    ElDatePickerInput, DimensionsView,
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      categories: [
        {
          value: 'active',
          text: 'Активные',
        },
        {
          value: 'completed',
          text: 'Завершенные',
        },
        {
          value: 'graduated',
          text: 'Утвержденные',
        },
      ],
      routeIds: [],
      auctionsId: null,
      auctionRoutes: null,
      auctionName: null,
      showComment: null,
      types: [],
      contractors: [],
      statuses: [],
      managers: [],
      firstDepartments: [],
      lastDepartments: [],
      filter: {
        category: 'active',
        route_id: [],
        transportation_id: null,
        arrival_date_from: null,
        arrival_date_to: null,
        start_date_from: null,
        start_date_to: null,
        stop_date_from: null,
        stop_date_to: null,
        contractor: [],
        first_point: [],
        last_point: [],
        status: [],
        page: 1,
        limit: 100,
        ready: false,
      },
      loading: false,
      columns: [
        'routeCodes',
        'routeWays',
        'transportationNames',
        'transportationType',
        'bodyTypes',
        'startPrice',
        'planDatesOfFirstPointArrive',
        'startDate',
        'stopDate',
        'enableOffers',
        'activeBid',
        'myBid',
        'dimensions',
        'status',
        'actions',
      ],
      options: {
        customFilters: [
          'category',
          'route_id',
          'transportation_id',
          'arrival_date_from',
          'arrival_date_to',
          'start_date_from',
          'start_date_to',
          'stop_date_from',
          'stop_date_to',
          'first_point',
          'last_point',
          'contractor',
          'status',
        ],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.contractorsAuctionsRoutesList();
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return contractorsAuctionsList(this.$store.state.user.ownerId, Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          data.items.forEach((item) => {
            if (item.auctionRoutes) {
              for (let auctionRoute in item.auctionRoutes) {
                if (item.auctionRoutes[auctionRoute].route.comment && 
                (item.auctionRoutes[auctionRoute].route.comment.comment !== '' ||
                item.auctionRoutes[auctionRoute].route.comment.comment !== undefined)) {
                  item.commentInfo = true;
                }
            };
            }
          });
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'routeCodes': 'Коды рейсов',
          'routeWays': 'Маршруты рейсов',
          'transportationNames': 'Тип перевозки',
          'transportationType': 'Вид перевозки',
          'bodyTypes': 'Разре-шенные типы кузова',
          'startPrice': 'Начальная ставка',
          'planDatesOfFirstPointArrive': 'Плановая дата прибытия',
          'startDate': 'Старт аукциона',
          'stopDate': 'Окончание аукциона',
          'enableOffers': 'Возможны встречные предложения',
          'activeBid': 'Лидирующая ставка',
          'myBid': 'Ваша ставка',
          'dimensions': 'Габариты',
          'status': 'Статус',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        perPage: 100,
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  computed: {
    dimensionsList(auctionRouteId, dimension, optionalDimensions) {
      return (auctionRouteId, dimension, optionalDimensions) => {
        return {
          id: auctionRouteId,
          ...(dimension && {dimension: dimension}),
          ...(optionalDimensions?.length > 0 && {optionalDimensions: optionalDimensions}),
        };
      };
    },
  },
  created() {
    this.initRouteParams();
    this.collectData();
  },
  mounted() {
    this.routesTransportationTypesList();
    this.contractorRoutesManagersList();
    this.firstDepartmentsList();
    this.lastDepartmentsList();
    const queries = this.$router.currentRoute.query;
    /**
     * Если в URL есть параметр 'limit'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.limit) {
      this.$refs.table.setLimit(queries.limit);
    }
    /**
     * Если в URL есть параметр 'page'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    const that = this;
    Event.$on('vue-tables.pagination', (data) => {
      that.filter.page = data;
      that.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  methods: {
    alternativeAmountValue(vat, value) {
      if (value) {
        if (!vat) {
          return '(' + this.numberRUFormat(valueToValueWithVAT(value)) + ' руб. С НДС)';
        } else {
          return '(' + this.numberRUFormat(valueToValueWithoutVAT(value)) + ' руб. Без НДС)';
        }
      } else {
        return '';
      }
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    },
    showCommentModal(auction) {
      this.showComment = true;
      this.auctionsId = auction.id;
      this.auctionRoutes = [];
      for (let auctionRoute in auction.auctionRoutes) {
        this.auctionRoutes.push(auction.auctionRoutes[auctionRoute].route);
      };
      this.auctionName = auction.name;
    },
    async firstDepartmentsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.firstDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    async lastDepartmentsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.lastDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    async contractorsAuctionsRoutesList(name) {
      this.loading = true;
      const params = {limit: 100};
      const query = URLToQuery(this.$route.fullPath);
      if (query && query.status) {
        URLToQuery(this.$route.fullPath).status.map((item, index) => {
          params['status[' + index + ']'] = item;
        });
      }
      if (name) {
        params.query = name;
      }
      const response = await contractorsAuctionsRoutesList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.routeIds = response.data.items.map((item) => {
          return {value: item.id, text: item.routeCode};
        });
      }
      if (this.filter.route_id.length > 0) {
        this.contractorsSelectedRoutesList();
      }
      this.loading = false;
    },
    async contractorsSelectedRoutesList() {
      this.loading = true;
      const selectedRouteWays = {};
      this.filter.route_id.map((item, index) => {
        selectedRouteWays['id[' + index + ']'] = item;
      });
      const response = await contractorsAuctionsRoutesList(this.$store.state.user.ownerId, selectedRouteWays);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.routeIds = this.routeIds.filter((x) => x.value !== item.id);
          this.routeIds.unshift({value: item.id, text: item.routeCode});
        });
      }
      this.loading = false;
    },
    async routesTransportationTypesList() {
      this.loading = true;
      const response = await routesTransportationTypesList({limit: 100});
      if (response && response.status === 200) {
        this.types = response.data.items.map((item) => {
          return {value: item.id, text: item.name, color: item.color, description: item.description};
        });
        this.types.unshift({value: null, text: 'Все'});
      }
      this.loading = false;
    },
    confirmCancelAuction(auctionsId, type) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите отказаться от победы в аукционе?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.onCancel(auctionsId, type);
          }
        });
    },
    async onCancel(auctionsId, type) {
      this.loading = true;
      const response = await contractorsAuctionDeclineWin(this.$store.state.user.ownerId, auctionsId, type);
      if (response && response.status === 200) {
        this.showSuccess('Вы отказались от победы в аукционе');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    formatDatetimeWithOriginalTimezone(date) {
      const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return date ? moment(date, 'YYYY-MM-DDTHH:mm').tz(clientTimezone).format('DD.MM.YYYY HH:mm') : '';
    },
    formatDatetime(date) {
      return date ? moment(date).format('DD.MM.YYYY HH:mm') : '';
    },
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        if (field === 'category') {
          this.filter.status = [];
          if (this.filter.category === 'active') {
            this.statuses = [
              {
                value: 1,
                text: 'Ожидает начала',
              },
              {
                value: 2,
                text: 'Прием ставок',
              },
            ];
            this.columns = [
              'routeCodes',
              'routeWays',
              'transportationNames',
              'transportationType',
              'bodyTypes',
              'startPrice',
              'planDatesOfFirstPointArrive',
              'startDate',
              'stopDate',
              'enableOffers',
              'activeBid',
              'myBid',
              'dimensions',
              'status',
              'actions',
            ];
            this.options.headings.activeBid = 'Лидирующая ставка';
            this.options.headings.myBid = 'Ваша ставка';
          } else if (this.filter.category === 'completed') {
            this.statuses = [
              {
                value: 3,
                text: 'Нет победителя',
              },
              {
                value: 4,
                text: 'Есть победитель',
              },
            ];
            this.columns = [
              'routeCodes',
              'routeWays',
              'transportationNames',
              'transportationType',
              'bodyTypes',
              'startPrice',
              'planDatesOfFirstPointArrive',
              'startDate',
              'stopDate',
              'myBid',
              'dimensions',
              'status',
              'actions',
            ];
            this.options.headings.myBid = 'Конечная ставка/Встречное';
          } else if (this.filter.category === 'graduated') {
            this.statuses = [
              {
                value: 6,
                text: 'Утвержден',
              },
            ];
            this.columns = [
              'routeCodes',
              'routeWays',
              'transportationNames',
              'transportationType',
              'bodyTypes',
              'startPrice',
              'planDatesOfFirstPointArrive',
              'startDate',
              'stopDate',
              'myBid',
              'dimensions',
              'actions',
            ];
            this.options.headings.activeBid = 'Победитель и финальная ставка';
          }
        }
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateDateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] ? moment(this.filter[field]).format('YYYY-MM-DDTHH:mm') : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.category) {
        res.category = this.filter.category;
      }
      if (this.filter.route_id.length) {
        res.route_id = this.filter.route_id;
      }
      if (this.filter.transportation_id) {
        res.transportation_id = this.filter.transportation_id;
      }
      if (this.filter.arrival_date_from) {
        res.arrival_date_from = moment(this.filter.arrival_date_from).format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.arrival_date_to) {
        res.arrival_date_to = moment(this.filter.arrival_date_to).format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.start_date_from) {
        res.start_date_from = moment(this.filter.start_date_from).utc().format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.start_date_to) {
        res.start_date_to = moment(this.filter.start_date_to).utc().format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.stop_date_from) {
        res.stop_date_from = moment(this.filter.stop_date_from).utc().format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.stop_date_to) {
        res.stop_date_to = moment(this.filter.stop_date_to).utc().format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.contractor.length) {
        res.contractor = this.filter.contractor;
      }
      if (this.filter.status && this.filter.status.length) {
        res.status = this.filter.status;
      } else {
        if (this.filter.category === 'active') {
          res.status = [1, 2];
        } else if (this.filter.category === 'completed') {
          res.status = [3, 4];
        } else if (this.filter.category === 'graduated') {
          res.status = [6];
        }
        res.category = this.filter.category;
      }
      const storedFilter = {...this.filter};
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: storedFilter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
        this.contractorsAuctionsRoutesList();
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('transportation_id')) {
          params.transportation_id = Number(params.transportation_id);
        }
        if (params.hasOwnProperty('route_id')) {
          params.route_id = arrayStringToNumber(params.route_id);
        }
        if (params.hasOwnProperty('start_date_from')) {
          params.start_date_from = moment.utc(params.start_date_from).tz(clientTimezone).format('YYYY-MM-DDTHH:mm');
        }
        if (params.hasOwnProperty('start_date_to')) {
          params.start_date_to = moment.utc(params.start_date_to).tz(clientTimezone).format('YYYY-MM-DDTHH:mm');
        }
        if (params.hasOwnProperty('stop_date_from')) {
          params.stop_date_from = moment.utc(params.stop_date_from).tz(clientTimezone).format('YYYY-MM-DDTHH:mm');
        }
        if (params.hasOwnProperty('stop_date_to')) {
          params.stop_date_to = moment.utc(params.stop_date_to).tz(clientTimezone).format('YYYY-MM-DDTHH:mm');
        }
        if (params.hasOwnProperty('status')) {
          if ((params.status.indexOf('1') !== -1 ^ params.status.indexOf('2') !== -1)) {
            params.status = arrayStringToNumber(params.status);
          } else {
            delete params.status;
          }
        }
        if (params.hasOwnProperty('category')) {
          if (params.category === 'active') {
            this.filter.category = 'active';
            this.statuses = [
              {
                value: 1,
                text: 'Ожидает начала',
              },
              {
                value: 2,
                text: 'Прием ставок',
              },
            ];
            this.columns = [
              'routeCodes',
              'routeWays',
              'transportationNames',
              'transportationType',
              'bodyTypes',
              'startPrice',
              'planDatesOfFirstPointArrive',
              'startDate',
              'stopDate',
              'enableOffers',
              'activeBid',
              'myBid',
              'status',
              'dimensions',
              'actions',
            ];
            this.options.headings.activeBid = 'Лидирующая ставка';
            this.options.headings.myBid = 'Ваша ставка';
          } else if (params.category === 'completed') {
            this.filter.category = 'completed';
            this.statuses = [
              {
                value: 4,
                text: 'Есть победитель',
              },
            ];
            this.columns = [
              'routeCodes',
              'routeWays',
              'transportationNames',
              'transportationType',
              'bodyTypes',
              'startPrice',
              'planDatesOfFirstPointArrive',
              'startDate',
              'stopDate',
              'myBid',
              'status',
              'dimensions',
              'actions',
            ];
            this.options.headings.myBid = 'Конечная ставка/Встречное';
          } else if (params.category === 'graduated') {
            this.filter.category = 'graduated';
            this.statuses = [
              {
                value: 6,
                text: 'Утвержден',
              },
            ];
            this.columns = [
              'routeCodes',
              'routeWays',
              'transportationNames',
              'transportationType',
              'bodyTypes',
              'startPrice',
              'planDatesOfFirstPointArrive',
              'startDate',
              'stopDate',
              'myBid',
              'dimensions',
              'actions',
            ];
            this.options.headings.activeBid = 'Победитель и финальная ставка';
          }
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        this.filter.ready = false;
        this.$nextTick(()=> {
          Object.assign(this.filter, params);
          this.filter.ready = true;
        });
      } else {
        this.filter.ready = false;
        const params = this.$store.getters.getFilterParams(this.$options.name);
        if (params) {
          if (params.hasOwnProperty('route_id')) {
            params.route_id = arrayStringToNumber(params.route_id);
          }
          if (params.hasOwnProperty('status')) {
            if ((params.status.indexOf('1') !== -1 ^ params.status.indexOf('2') !== -1)) {
              params.status = arrayStringToNumber(params.status);
            } else {
              delete params.status;
            }
          }
          if (params.hasOwnProperty('category')) {
            if (params.category === 'active') {
              this.filter.category = 'active';
              this.statuses = [
                {
                  value: 1,
                  text: 'Ожидает начала',
                },
                {
                  value: 2,
                  text: 'Прием ставок',
                },
              ];
              this.columns = [
                'routeCodes',
                'routeWays',
                'transportationNames',
                'transportationType',
                'bodyTypes',
                'startPrice',
                'planDatesOfFirstPointArrive',
                'startDate',
                'stopDate',
                'enableOffers',
                'activeBid',
                'myBid',
                'status',
                'dimensions',
                'actions',
              ];
              this.options.headings.activeBid = 'Лидирующая ставка';
              this.options.headings.myBid = 'Ваша ставка';
            } else if (params.category === 'completed') {
              this.filter.category = 'completed';
              this.statuses = [
                {
                  value: 4,
                  text: 'Есть победитель',
                },
              ];
              this.columns = [
                'routeCodes',
                'routeWays',
                'transportationNames',
                'transportationType',
                'bodyTypes',
                'startPrice',
                'planDatesOfFirstPointArrive',
                'startDate',
                'stopDate',
                'myBid',
                'status',
                'dimensions',
                'actions',
              ];
              this.options.headings.myBid = 'Конечная ставка/Встречное';
            } else if (params.category === 'graduated') {
              this.filter.category = 'graduated';
              this.statuses = [
                {
                  value: 6,
                  text: 'Утвержден',
                },
              ];
              this.columns = [
                'routeCodes',
                'routeWays',
                'transportationNames',
                'transportationType',
                'bodyTypes',
                'startPrice',
                'planDatesOfFirstPointArrive',
                'startDate',
                'stopDate',
                'myBid',
                'dimensions',
                'actions',
              ];
              this.options.headings.activeBid = 'Победитель и финальная ставка';
            }
          }
          if (params.hasOwnProperty('page')) {
            params.page = Number(params.page);
          }
          this.$nextTick(()=> {
            Object.assign(this.filter, params);
            this.filter.ready = true;
          });
        } else {
          this.filter.ready = true;
        }
      }
    },
    async contractorRoutesManagersList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await routeOwnersList( params);
      if (response && response.status === 200) {
        this.managers = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      this.loading = false;
    },
    async collectData() {
      let dateNow = new Date;
      await enterToAuctionsPage({
        date: dateNow.toLocaleDateString("ru-Ru", {timeZone: "Europe/Moscow"}),
					time: dateNow.toLocaleTimeString("ru-Ru", {timeZone: "Europe/Moscow"}),
        sessionId: this.$cookies.get('Session'),
        organization: this.$store.getters['user'].company.replace(/[^А-яЁё A-Za-z]/g,''),
        clickType: 'Зашел на страницу торговли',
        pageType: 'Аукцион',
        inn: this.$store.getters["user"].INN,
      });
    },
  },
};
</script>

<style lang="scss">
  .auction-filter-tabs {
    margin: -1px;
    &__input {
      display: none;

      &:checked {

        ~ .auction-filter-tabs__display {
          color: #23282c;
          background-color: #fff;
          border-color: #c8ced3 #c8ced3 #fff;
        }
      }
    }
    &__display {
      background-color: transparent;
      margin-bottom: 0;
    }
  }

  .text-blue {
    color: #20a8d8;
    font-size: 12px;
    font-weight: 600; 
  }

  .text-standart {
    color: #5A5E65;
    font-size: 12px;
    font-weight: 600; 
  }
</style>
